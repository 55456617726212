<template>
	<basePopup :okFunc="close" :title="title" cancelText="" okText="확인">
		<p>*수락된 딜을 월 단위로 확인할 수 있습니다.</p>
		<p>*딜 명을 클릭하면 판매페이지가 열립니다.</p>

		<vSelect
			v-if="!isHottist"
			class="w-1/2 my-2"
			v-model="selectedProductGroup"
			:clearable="false"
			:options="productGroupOpts"
		/>

		<CalendarView
			id="dealCalendar"
			@click-item="openEditEvent"
			:items="dealList"
			:pr="ths"
			:show-date="calendarDate"
			cName="calendar"
			itemTop="2rem"
		>
			<div slot="header" class="mb-2 text-center">
				<b-button @click="changeMonth(-1)" size="sm" variant="no">
					<b-icon icon="chevron-left" />
				</b-button>

				<span class="mx-2 h4 top-0.5">{{ displayDate }}</span>

				<b-button @click="changeMonth(1)" size="sm" variant="no">
					<b-icon icon="chevron-right" />
				</b-button>
			</div>
		</CalendarView>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'
import { CalendarView } from 'vue-simple-calendar'

export default {
	components: { basePopup, CalendarView },
	data() {
		return {
			isHottist: true,
			isShow: false,
			title: '',
			calendarDate: new Date(),
			apiUrl: '',
			originList: [],
			classList: ['deal-purple', 'deal-red', 'deal-blue-green', 'deal-orange', 'deal-green'],

			lastOpt: {},
			productGroupOpts: [],
			selectedProductGroup: '',
		}
	},
	methods: {
		open(item, isHottist = true) {
			//핫티스트, 파트너사
			//날짜 빼곤 바뀔 게 없으므로 여기서 바로 초기화
			this.isHottist = isHottist
			this.calendarDate = new Date()
			this.productGroupOpts = [{ label: '전체', value: '' }]

			if (isHottist) {
				this.title = `${item.name} / ${item.hottId} / ${item.currentInstaId} 핫티스트 딜 캘린더`
				this.lastOpt = {
					isExcel: 0,
					hottId: item.hottId,
					orderBy: [
						{
							fieldName: 'dealEndDate',
							operation: 'desc',
						},
					],
					paging: getPaging(0, 100),
					searchYearMonth: '',
				}
				this.apiUrl = '/hottistSelect/dealList'
			} else {
				//파트너사는 따로 상품 그룹 가져와서 추가
				this.selectedProductGroup = this.productGroupOpts[0]
				postApi('/partnerSelect/partnerDetailProductGroupList', {
					storeSeq: item.storeSeq,
					paging: getPaging(0, 9999),
					orderBy: getOrder('productGroupIdx'),
				}).then(res => {
					//this.productGroupOpts =

					res.list.map(v => {
						this.productGroupOpts.push({
							label: `${v.productGroupIdx} / ${v.brandName} ${v.productGroupName}`,
							value: v.productGroupIdx,
						})
					})
				})

				this.title = `${item.comName} / ${item.contractPicName} 딜 캘린더`

				//날짜 빼곤 바뀔 게 없으므로 여기서 바로 초기화
				this.lastOpt = {
					storeSeq: item.storeSeq,
					searchYearMonth: '',
				}
				this.apiUrl = '/dealSelect/partnerDealList'
			}
			this.isShow = true
			this.changeMonth(0)
		},
		close() {
			this.isShow = false
		},
		changeMonth(val) {
			if (val) this.calendarDate = this.calendar ? this.calendar.getIncrementedPeriod(val) : this.calendarDate
			//처음 초기화될 때는 아직 calendar가 없다?
			this.lastOpt.searchYearMonth = this.calendarDate.get_time('m', 0, '')
			this.originList = []
			postApi(this.apiUrl, this.lastOpt).then(res => {
				if (this.isHottist) res = res.list
				if (res.length) {
					let ct = 1
					this.originList = res.map((v, i) => {
						if (this.isHottist) {
							v.startDate = v.startDt
							v.endDate = v.endDt
							v.name = `${v.dealNum} - ${v.dealName}`
						} else v.name = `${v.brandName} ${v.productGroupName}`
						return {
							id: v.dealNum + ct++,
							//dealNum이 없을 수도 있다.. 큰 문제는 아닌데 없으면 key error 발생
							title: `${v.name} (${v.startDate.get_time('d', 0, '/').substr(5)}~${v.endDate
								.get_time('d', 0, '/')
								.substr(5)})`,
							startDate: v.startDate.get_time('d'),
							endDate: v.endDate.get_time('d'),
							classes: this.classList[i % 5],
							dealNum: v.dealNum,
							productGroupIdx: v.productGroupIdx,
						}
					})
				}
			})
		},
		openEditEvent(item) {
			if (item.originalItem.dealNum) window.open(`${dealHost}/${item.originalItem.dealNum}`, '_blank')
		},
	},
	computed: {
		displayDate() {
			return this.calendarDate.get_time('m', 0, '한글')
		},
		dealList() {
			if (!this.isHottist && this.selectedProductGroup.value)
				return this.originList.filter(v => v.productGroupIdx == this.selectedProductGroup.value)
			return this.originList
		},
	},
}
</script>

'deal-purple', 'deal-red', 'deal-blue-green', 'deal-orange', 'deal-green'
<style>
#dealCalendar .cv-week {
	min-height: 8rem;
}

#dealCalendar .week6 {
	display: none;
}

#dealCalendar .cv-item {
	cursor: pointer;
}

#dealCalendar .cv-item {
	font-size: 0.8rem;
}

#dealCalendar .deal-purple {
	color: #7367f0;
	background-color: rgba(115, 103, 240, 0.12);
	border: rgba(115, 103, 240, 0.1) 1px solid;
}

#dealCalendar .deal-red {
	color: #ea5455;
	background-color: rgba(234, 84, 85, 0.12);
	border: rgba(234, 84, 85, 0.1) 1px solid;
}

#dealCalendar .deal-blue-green {
	color: #00cfe8;
	background-color: rgba(0, 207, 232, 0.12);
	border: rgba(0, 207, 232, 0.1) 1px solid;
}

#dealCalendar .deal-orange {
	color: #ff9f43;
	background-color: rgba(255, 159, 67, 0.12);
	border: rgba(255, 159, 67, 0.1) 1px solid;
}

#dealCalendar .deal-green {
	color: #28c76f;
	background-color: rgba(40, 199, 111, 0.12);
	border: rgba(40, 199, 111, 0.1) 1px solid;
}
</style>
